import React, { useEffect, useState } from "react";
import Lottie from "lottie-react";
import locationErrorAnimation from "../animation/location-not-found.json";

const allowedLocations = [
  { lat: -6.865, lon: -78.826 }, // Celendín
];

const getCityAndRegion = async (lat, lng) => {
  const apiKey = process.env.REACT_APP_GEO_CODE_KEY;
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}&language=es`;

  try {
    const response = await fetch(url);
    const data = await response.json();

    if (data.status !== "OK") {
      return null;
    }

    const addressComponents = data.results[0]?.address_components;

    if (!addressComponents) return null;

    const city = addressComponents.find((comp) =>
      comp.types.includes("locality")
    )?.long_name;

    const region = addressComponents.find((comp) =>
      comp.types.includes("administrative_area_level_1")
    )?.long_name;

    return { city, region };
  } catch (error) {
    return null;
  }
};

const isLocationAllowed = async (userLat, userLon) => {
  const { city, region } = await getCityAndRegion(userLat, userLon);
  return city === "Celendin" && region === "Cajamarca";
};

const LocationGuard = ({ children }) => {
  const [locationAllowed, setLocationAllowed] = useState(null);

  useEffect(() => {
    const override = localStorage.getItem("locationOverride");
    if (override === "true") {
      setLocationAllowed(true);
      return;
    }

    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;
        const allowed = await isLocationAllowed(latitude, longitude);
        setLocationAllowed(allowed);
      },
      (error) => {
        setLocationAllowed(false);
      }
    );
  }, []);

  const handleOverride = () => {
    localStorage.setItem("locationOverride", "true");
    setLocationAllowed(true);
  };

  if (locationAllowed === null) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="text-center">
          <div className="spinner-border text-primary mb-3" role="status"></div>
          <p className="text-muted">Detectando ubicación...</p>
        </div>
      </div>
    );
  }

  if (!locationAllowed) {
    return (
      <div className="container d-flex flex-column justify-content-center align-items-center vh-100 text-center">
        <div className="card shadow p-4">
          <Lottie
            animationData={locationErrorAnimation}
            style={{ height: 200 }}
          />
          <h1 className="text-danger mb-3">
            Servicio no disponible en tu ubicación
          </h1>
          <p className="text-muted">
            Actualmente solo ofrecemos cobertura en <strong>Celendín</strong>.
          </p>
          <button className="btn btn-primary mt-3" onClick={handleOverride}>
            Pedir para alguien en Celendín
          </button>
        </div>
      </div>
    );
  }

  return <>{children}</>;
};

export default LocationGuard;
