import React, { useEffect, useState } from "react";
// import clutch from "../../images/clutch-dark.png";
import member1 from "../../images/01.jpg";
import member3 from "../../images/03.jpg";
// import member4 from "../../images/04.jpg";
import idea from "../../images/idea.gif";
import team from "../../images/team.gif";
import award from "../../images/award.gif";

import flipkartlogo from "../../images/Aliado1.png";
import amazonlogo from "../../images/Aliado2.png";
import blinkit from "../../images/Aliado3.png";
import smartshop from "../../images/Aliado4.png";
import aliado5 from "../../images/Aliado5.png";
import aliado6 from "../../images/Aliado6.png";
import aliado7 from "../../images/Aliado7.png";
import aliado8 from "../../images/Aliado8.png";
import aliado9 from "../../images/Aliado9.png";

// import bgimage from "../../images/contact-bg.png";
// import cover from "../../images/cover.jpg";

import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import SocialNetworksCarousel from "./SocialNetworksCarousel";
import { MagnifyingGlass } from "react-loader-spinner";
import { Slide, Zoom } from "react-awesome-reveal";
import ScrollToTop from "../ScrollToTop";

const AboutUs = () => {
  // loading
  const [loaderStatus, setLoaderStatus] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoaderStatus(false);
    }, 1500);
  }, []);

  return (
    <div>
      <div>
        {loaderStatus ? (
          <div className="loader-container">
            {/* <PulseLoader loading={loaderStatus} size={50} color="#1A80E5" /> */}
            <MagnifyingGlass
              visible={true}
              height="100"
              width="100"
              ariaLabel="magnifying-glass-loading"
              wrapperStyle={{}}
              wrapperclassName="magnifying-glass-wrapper"
              glassColor="#c0efff"
              color="#1A80E5"
            />
          </div>
        ) : (
          <>
            <>
              <ScrollToTop />
            </>
            <>
              {/* Hero */}
              <section className="position-relative pt-5">
                {/* Background */}
                <div
                  className="position-absolute top-0 start-0 w-100 bg-position-bottom-center bg-size-cover bg-repeat-0"
                  style={{
                    backgroundImage: "url(assets/img/about/hero-bg.svg)",
                  }}
                >
                  <div className="d-lg-none" style={{ height: 960 }} />
                  <div className="d-none d-lg-block" style={{ height: 768 }} />
                </div>
                {/* Content */}
                <div className="container position-relative zindex-5 pt-5">
                  <div className="row">
                    <div className="col-lg-6">
                      {/* Breadcrumb */}

                      {/* Text */}
                      <Slide direction="down">
                        <h1 className="pb-2 pb-md-3">
                          Acerca de Altoke Shop
                        </h1>
                        <h3 className="pb-2 pb-md-3">
                          Tu solución rápida y confiable para transporte y
                          delivery.
                        </h3>
                        <p
                          className="fs-xl pb-4 mb-1 mb-md-2 mb-lg-3"
                          style={{ maxWidth: 526 }}
                        >
                          En Altoke, hacemos que moverte y recibir lo que
                          necesitas sea más fácil que nunca. Ofrecemos servicios
                          de taxi urbano y fuera de la ciudad, envíos de
                          paquetes y delivery de los mejores restaurantes
                          afiliados. Todo en una sola plataforma, con la
                          seguridad y rapidez que mereces.
                        </p>
                        {/* <img
                        src={clutch}
                        className=" d-dark-mode-none"
                        width={175}
                        alt="Clutch"
                      /> */}
                        <img
                          src="assets/img/about/clutch-light.png"
                          className="d-none d-dark-mode-block"
                          width={175}
                          alt="Clutch"
                        />
                      </Slide>
                    </div>
                    {/* Images */}
                    <div className="col-lg-6 mt-xl-3 pt-5 pt-lg-4">
                      <div className="row row-cols-2 gx-3 gx-lg-4">
                        <div className="col pt-lg-5 mt-lg-1">
                          <Zoom>
                            <img
                              src={member1}
                              className="d-block rounded-3 mb-3 mb-lg-4"
                              alt="member"
                            />
                          </Zoom>
                          {/* <img
                      src={member2}
                      className="d-block rounded-3"
                     alt="member"
                    /> */}
                        </div>
                        <div className="col">
                          <Zoom>
                            <img
                              src={member3}
                              className="d-block rounded-3 mb-3 mb-lg-4"
                              alt="member"
                            />
                          </Zoom>
                          {/* <img
                      src={member4}
                      className="d-block rounded-3"
                     alt="member"
                    /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </>

            <>
              <section className="container mt-8 mb-5 pt-lg-5" id="benefits">
                <Swiper
                  className="swiper pt-3"
                  modules={[]} // Use the modules prop to include the Pagination module
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  breakpoints={{
                    500: { slidesPerView: 2 },
                    991: { slidesPerView: 3 },
                  }}
                >
                  <div className="swiper-wrapper pt-4">
                    <SwiperSlide className="swiper-slide border-end-lg px-2">
                      <div className="text-center">
                        <Zoom>
                          <img
                            src={idea}
                            width="100"
                            alt="Bulb icon"
                            className="d-block mb-4 mx-auto"
                          />
                        </Zoom>
                        <Slide direction="up">
                          <h4 className="mb-2 pb-1">Rápido y seguro</h4>
                          <p className="mx-auto" style={{ maxWidth: "336px" }}>
                            Conductores y repartidores verificados.
                          </p>
                        </Slide>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className="swiper-slide border-end-lg px-2">
                      <div className="text-center">
                        <Zoom>
                          <img
                            src={award}
                            width="100"
                            alt="Award icon"
                            className="d-block mb-4 mx-auto"
                          />
                        </Zoom>
                        <Slide direction="up">
                          <h4 className="mb-2 pb-1">Variedad de opciones</h4>
                          <p className="mx-auto" style={{ maxWidth: "336px" }}>
                            Desde transporte hasta comida a domicilio.
                          </p>
                        </Slide>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className="swiper-slide px-2">
                      <div className="text-center">
                        <Zoom>
                          <img
                            src={team}
                            width="100"
                            alt="Team icon"
                            className="d-block mb-4 mx-auto"
                          />
                        </Zoom>
                        <Slide direction="up">
                          <h4 className="mb-2 pb-1">Cobertura amplia</h4>
                          <p className="mx-auto" style={{ maxWidth: "336px" }}>
                            Servicio dentro y fuera de la ciudad.
                          </p>
                        </Slide>
                      </div>
                    </SwiperSlide>
                  </div>
                  <div className="swiper-pagination position-relative pt-2 pt-sm-3 mt-4"></div>
                </Swiper>
              </section>
            </>

            <>
              {/* Video showreel */}
              {/* <section className="container text-center pb-5 mt-n2 mt-md-0 mb-md-2 mb-lg-4">
          <div className="position-relative rounded-3 overflow-hidden">
            <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center zindex-5">
              <a
                href="https://www.youtube.com/watch?v=zPo5ZaH6sW8"
                className="btn btn-video btn-icon btn-xl stretched-link bg-white"
                data-bs-toggle="video"
              >
                <i className="bx bx-play" />
              </a>
            </div>
            <span className="position-absolute top-0 start-0 w-100 h-100  opacity-35" />
            <img
              src={cover}
              className="d-block w-100"
              alt="Cover "
            />
          </div>

            <>
              {/* Awards */}
              <section className="container mt-8 mb-5 pb-3 pb-md-4 pb-lg-5">
                <div className="row gy-4 py-xl-2">
                  <div className="col-md-4">
                    {/* <h2 className="mb-0 text-md-start text-center">Our Partners</h2> */}
                    <div className="info-whydiff">
                      <div className="section-title-left pt-80">
                        <h3 className="party" style={{ fontSize: "38px" }}>
                          <Slide direction="down" delay={0.5}>
                            Nuestros Aliados
                          </Slide>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="container mt-8 mb-5 pb-3 pb-md-4 pb-lg-5">
                <div className="row gy-4 py-xl-2">
                  <div className="col-lg-13 offset-lg-12 col-md-8">
                    <div className="row row-cols-sm-4 row-cols-2 gy-4">
                      <div className="col">
                        <div className="position-relative text-center">
                          <Zoom>
                            <img
                              src={flipkartlogo}
                              width={100}
                              alt="Webby"
                              className="d-block mx-auto mb-3"
                            />
                          </Zoom>
                          <Link
                            to="#"
                            className="text-body justify-content-center fs-sm stretched-link text-decoration-none"
                          >
                            El Gran Combo
                          </Link>
                        </div>
                      </div>
                      <div className="col">
                        <div className="position-relative text-center">
                          <Zoom>
                            <img
                              src={amazonlogo}
                              width={100}
                              alt="CSSDA"
                              className="d-block mx-auto mb-3"
                            />
                          </Zoom>
                          <Link
                            to="#"
                            className="text-body justify-content-center fs-sm stretched-link text-decoration-none"
                          >
                            La Dolce Pizza
                          </Link>
                        </div>
                      </div>
                      <div className="col">
                        <div className="position-relative text-center">
                          <Zoom>
                            <img
                              src={blinkit}
                              width={100}
                              // height={57}
                              alt="Awwwards"
                              className="d-block mx-auto mb-3"
                            />
                          </Zoom>
                          <Link
                            to="#"
                            className="text-body justify-content-center fs-sm stretched-link text-decoration-none"
                          >
                            Tiesto
                          </Link>
                        </div>
                      </div>
                      <div className="col">
                        <div className="position-relative text-center">
                          <Zoom>
                            <img
                              src={smartshop}
                              width={100}
                              alt="FWA"
                              className="d-block mx-auto mb-3"
                            />
                          </Zoom>
                          <Link
                            to="#"
                            className="text-body justify-content-center fs-sm stretched-link text-decoration-none"
                          >
                            La Pinche Rola
                          </Link>
                        </div>
                      </div>
                      <div className="col">
                        <div className="position-relative text-center">
                          <Zoom>
                            <img
                              src={aliado5}
                              width={100}
                              alt="FWA"
                              className="d-block mx-auto mb-3"
                            />
                          </Zoom>
                          <Link
                            to="#"
                            className="text-body justify-content-center fs-sm stretched-link text-decoration-none"
                          >
                            Mishki Piko
                          </Link>
                        </div>
                      </div>
                      <div className="col">
                        <div className="position-relative text-center">
                          <Zoom>
                            <img
                              src={aliado6}
                              width={100}
                              alt="FWA"
                              className="d-block mx-auto mb-3"
                            />
                          </Zoom>
                          <Link
                            to="#"
                            className="text-body justify-content-center fs-sm stretched-link text-decoration-none"
                          >
                            Spring
                          </Link>
                        </div>
                      </div>
                      <div className="col">
                        <div className="position-relative text-center">
                          <Zoom>
                            <img
                              src={aliado7}
                              width={100}
                              alt="FWA"
                              className="d-block mx-auto mb-3"
                            />
                          </Zoom>
                          <Link
                            to="#"
                            className="text-body justify-content-center fs-sm stretched-link text-decoration-none"
                          >
                            Cuchi Cuchi's
                          </Link>
                        </div>
                      </div>
                      <div className="col">
                        <div className="position-relative text-center">
                          <Zoom>
                            <img
                              src={aliado8}
                              width={100}
                              alt="FWA"
                              className="d-block mx-auto mb-3"
                            />
                          </Zoom>
                          <Link
                            to="#"
                            className="text-body justify-content-center fs-sm stretched-link text-decoration-none"
                          >
                            San Isidro Labrador
                          </Link>
                        </div>
                      </div>
                      <div className="col">
                        <div className="position-relative text-center">
                          <Zoom>
                            <img
                              src={aliado9}
                              width={100}
                              alt="FWA"
                              className="d-block mx-auto mb-3"
                            />
                          </Zoom>
                          <Link
                            to="#"
                            className="text-body justify-content-center fs-sm stretched-link text-decoration-none"
                          >
                            Multiservicios Sifuentes
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </>
            <>
              <SocialNetworksCarousel />
            </>
          </>
        )}
      </div>
    </div>
  );
};
export default AboutUs;
